var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-modal',{attrs:{"title":"新建用户","width":1080,"visible":_vm.visible,"confirmLoading":_vm.loading},on:{"ok":() => { _vm.$emit('ok') },"cancel":() => { _vm.$emit('cancel') }}},[_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form',_vm._b({attrs:{"form":_vm.form}},'a-form',_vm.formLayout,false),[_c('a-form-item',{attrs:{"label":"用户名","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"extra":"用户名只能由字母、数字和下划线组成,且必须以字母开头、最低六位字符长度"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['username',
                        {rules: [
                          {required: true, message: '请输入'},
                          {min:6, max:50, message: '输入不符合长度限制'}
                        ]}]),expression:"['username',\n                        {rules: [\n                          {required: true, message: '请输入'},\n                          {min:6, max:50, message: '输入不符合长度限制'}\n                        ]}]"}]})],1),_c('a-form-item',{attrs:{"label":"昵称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['nickname', {rules: [{required: true, message: '请输入'}]}]),expression:"['nickname', {rules: [{required: true, message: '请输入'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"密码","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol,"extra":"密码最低设置八位字符长度"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {rules: [{required: true, message: '请输入'}, {min:8, message: '输入不符合长度限制'}]}]),expression:"['password', {rules: [{required: true, message: '请输入'}, {min:8, message: '输入不符合长度限制'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"账号分类","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'role',
            {
              rules: [
                { required: true, message: '请选择' },
              ],
            },
          ]),expression:"[\n            'role',\n            {\n              rules: [\n                { required: true, message: '请选择' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"选择"},on:{"change":_vm.handleRoleChange}},[_c('a-select-option',{attrs:{"value":1}},[_vm._v(" 管理员 ")]),_c('a-select-option',{attrs:{"value":2}},[_vm._v(" 企业 ")]),_c('a-select-option',{attrs:{"value":3}},[_vm._v(" 物业 ")]),_c('a-select-option',{attrs:{"value":4}},[_vm._v(" 协管 ")])],1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"corporation_id"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['corporation_id']),expression:"['corporation_id']"}]})],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_show),expression:"is_show"}],attrs:{"label":"关联企业","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:(['corporation', {rules: [{required: false, message: '必填项，请填写信息'}]}]),expression:"['corporation', {rules: [{required: false, message: '必填项，请填写信息'}]}]"}],attrs:{"data-source":_vm.dataSource,"placeholder":"请输入"},on:{"select":_vm.onSelect,"search":_vm.onSearch,"change":_vm.onChange}}),(!!_vm.tip)?_c('div',{staticClass:"ant-form-explain"},[_vm._v(" "+_vm._s(_vm.tip)+" ")]):_vm._e()],1),_c('a-form-item',{attrs:{"label":"权限","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'permission_group_id',
            {
              rules: [
                { required: false, message: '请选择' },
              ],
            },
          ]),expression:"[\n            'permission_group_id',\n            {\n              rules: [\n                { required: false, message: '请选择' },\n              ],\n            },\n          ]"}],attrs:{"placeholder":"选择"},on:{"change":_vm.handlePermissionChange}},_vm._l((_vm.allPerlist),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"是否活跃","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-switch',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_active', {initialValue: true, valuePropName: 'checked'}]),expression:"['is_active', {initialValue: true, valuePropName: 'checked'}]"}]})],1),_c('a-form-item',{attrs:{"label":"邮箱","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['email', {rules: [{required: false, message: '请输入'}]}]),expression:"['email', {rules: [{required: false, message: '请输入'}]}]"}]})],1),_c('a-form-item',{attrs:{"label":"手机号","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['cellphone', {rules: [{required: false, message: '请输入'}]}]),expression:"['cellphone', {rules: [{required: false, message: '请输入'}]}]"}]})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }