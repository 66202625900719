<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="昵称">
              <a-input v-model="queryParam.nickname" placeholder="请输入"/>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="手机号">
              <a-input v-model="queryParam.cellphone" placeholder="请输入"/>
            </a-form-item>
          </a-col>
          <a-col :md="2" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator" v-action:add>
      <a-button type="primary" icon="plus" @click="handleAdd">新建</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="img" slot-scope="img">
        <a-avatar
          slot="img"
          :src="img"
          shape="square"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a @click="handleEdit(record)" v-if="false">编辑</a>
          <a @click="setUserLevel(record)">修改等级</a>
          <a-divider type="vertical" />
          <a @click="handleUserAddress(record)">地址</a>
          <a-divider type="vertical" />
          <a-popconfirm
            title="确定要删除么？"
            ok-text="确定"
            cancel-text="取消"
            @confirm="handleDelet(record)"
          >
            <a>删除</a>
          </a-popconfirm>
          <a-divider type="vertical" v-if="false"/>
          <a-dropdown v-if="false">
            <a class="ant-dropdown-link">
              更多 <a-icon type="down" />
            </a>
            <a-menu slot="overlay">
              <a-menu-item>
                <a @click="setUserLevel(record)">修改等级</a>
              </a-menu-item>
              <a-menu-item>
                <a @click="handleUserAddress(record)">地址</a>
              </a-menu-item>
              <a-menu-item>
                <a-popconfirm
                  title="确定要删除么？"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="handleDelet(record)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>

        </template>
      </span>
    </s-table>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <set-user-level-form
      v-if="setUserLevelVisible"
      ref="setUserLevelModal"
      :visible="setUserLevelVisible"
      :confirm-loading="setUserLevelLoading"
      :model="setUserMdl"
      @ok="setUserLevelOk"
      @cancel="handleResetCancel"
    />
    <a-drawer
      :title="this.userMdl.nickname + '地址列表'"
      placement="right"
      :closable="false"
      width="900"
      v-if="addressVisible"
      :visible.sync="addressVisible"
      @close="onAddressClose"
      :drawer-style="{ position: 'absolute' }"
      style="position: absolute"
    >
      <a-table
        size="small"
        rowKey="id"
        :loading="addressLoading"
        :columns="addressColumns"
        :data-source="addresses" >
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleUpdateAddress(record)">编辑</a>
            <a-divider type="vertical" />
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDeleteAddress(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </a-table>
    </a-drawer>
    <update-address-form
      v-if="updateAddressVisible"
      ref="updateAddressModal"
      :visible.sync="updateAddressVisible"
      :loading="updateAddressConfirmLoading"
      :model="addressMdl"
      @cancel="handleUpdateAddressCancel"
      @ok="handleUpdateAddressOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import {
  user_address_list,
  user_address_delete,
  user_address_update,
  user_create,
  user_delete,
  user_list,
  user_update
} from '@/api/applet_shop_user'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import SetUserLevelForm from '../modules/SetUserLevelForm'
import UpdateAddressForm from '../modules/UpdateAddressForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateForm,
    SetUserLevelForm,
    UpdateAddressForm
  },
  data () {
    return {
      pageNum: 1,
      setUserLevelVisible: false,
      setUserLevelLoading: false,
      setUserMdl: {},
      dataSource: [],
      userMdl: {},
      addressMdl: {},
      updateAddressVisible: false,
      addressVisible: false,
      addressLoading: false,
      addresses: [],
      tip: '',
      corList: [],
      loading: false,
      confirmLoading: false,
      updateAddressConfirmLoading: false,
      dictionaries: Dictionaries,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '头像',
          dataIndex: 'avatar_url',
          scopedSlots: { customRender: 'img' }
        },
        {
          title: '用户名',
          dataIndex: 'username'
        },
        {
          title: '昵称',
          dataIndex: 'nickname'
        },
        {
          title: '真实姓名',
          dataIndex: 'real_name'
        },
        {
          title: '手机号',
          dataIndex: 'cellphone'
        },
        {
          title: '身份证号',
          dataIndex: 'id_number'
        },
        {
          title: '用户等级',
          dataIndex: 'level'
        },
        {
          title: '操作',
          width: '150',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      addressColumns: [
        {
          title: '收件人',
          width: 100,
          dataIndex: 'receiving_name'
        },
        {
          title: '电话',
          width: 120,
          dataIndex: 'receiving_phone'
        },
        {
          title: '省',
          width: 120,
          dataIndex: 'province'
        },
        {
          title: '市',
          width: 120,
          dataIndex: 'city'
        },
        {
          title: '区',
          width: 120,
          dataIndex: 'county'
        },
        {
          title: '详细地址',
          width: 180,
          dataIndex: 'address'
        },
        {
          title: '是否默认',
          width: 80,
          dataIndex: 'is_default',
          customRender: (text) => text ? '是' : '否'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '180px',
          align: 'center',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return user_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      }
    }
  },
  created () {
    console.log(this.$Dictionaries)
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      const page = sessionStorage.getItem('CurrentPage')
      if (page) {
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        }
      }
    },
    setUserLevel (record) {
      this.setUserMdl = record
      this.setUserLevelVisible = true
      this.setUserLevelLoading = false
    },
    setUserLevelOk () {
      const form = this.$refs.setUserLevelModal.form
      this.setUserLevelLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
           const param = Object.assign({}, values)
          user_update(param, this.setUserMdl.id).then(res => {
            if (res.code === 1000) {
              // 刷新表格
              this.$refs.table.refresh()
            }
          }).finally(() => {
            // 重置表单数据
            form.resetFields()
            this.setUserLevelVisible = false
            this.setUserLevelLoading = false
            this.setUserMdl = {}
          })
        } else {
          this.setUserLevelLoading = false
        }
      })
    },
    //  弹框处理区域
    handleAdd () {
      this.mdl = null
      this.visible = true
    },
    handleUserAddress (record) {
      console.log(record)
      this.addresses = []
      this.userMdl = record
      this.addressVisible = true
      this.getUserAddress()
    },
    getUserAddress () {
      this.addressLoading = true
      user_address_list({ user_id: this.userMdl.id }).then(res => {
        this.addresses = res.data.entries
      }).finally(() => {
        this.addressLoading = false
      })
    },
    handleResetCancel () {
      this.setUserLevelVisible = false
      const form = this.$refs.setUserLevelModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      sessionStorage.setItem('CurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      // this.$emit('onEdit', record)
      this.$router.push({ path: '/users/userList/UserInformation/' + record.id })
    },
    handleDelet (record) {
      user_delete(record.id).then((res) => {
        console.log(res)
        this.$refs.table.refresh()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
            // 新增
          user_create(values).then(res => {
              this.visible = false
              this.confirmLoading = false
              // 重置表单数据
              form.resetFields()
              // 刷新表格
              this.$refs.table.refresh()
            }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    // 列表
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    onAddressClose () {
      this.addressVisible = false
      this.addressLoading = false
      this.userMdl = undefined
    },
    handleUpdateAddressCancel () {
      this.updateAddressVisible = false
    },
    handleUpdateAddressOk () {
      const forms = this.$refs.updateAddressModal.form
      this.updateAddressConfirmLoading = true
      forms.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          user_address_update(values, this.addressMdl.id).then(res => {
            this.visible = false
            this.updateAddressConfirmLoading = false
            // 重置表单数据
            forms.resetFields()
            // 刷新表格
            if (res.code === 1000) {
              this.getUserAddress()
            }
          }).finally(() => {
            this.updateAddressVisible = false
            this.updateAddressConfirmLoading = false
          })
        } else {
          this.updateAddressConfirmLoading = false
        }
      })
    },
    handleUpdateAddress (record) {
      // 修改用户地址
      console.log(record)
      this.addressMdl = record
      this.updateAddressVisible = true
    },
    handleDeleteAddress (record) {
      // 删除用户地址
      console.log(record)
      user_address_delete(record.id).then(res => {
        if (res.code === 1000) {
          this.getUserAddress()
        }
      })
    }
  }
}
</script>
